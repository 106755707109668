<template>
  <new-page :width="1450">
    <div class="articleMain">
      <div v-wechat-title="$route.meta.title"></div>
      <div class="container">
        <div>
          <el-skeleton
            class="skeleton"
            :rows="13"
            animated
            v-if="articleLoading == true"
          />
        </div>
        <div style="display: flex" v-if="articleLoading == false">
          <div style="flex: 1">
            <div
              class="coverPhoto"
              :style="{
                background:
                  'url(' +
                  photoUrl(articleInfo.cover_photo) +
                  ') no-repeat center center/cover'
              }"
            >
              <div class="headerText">
                <div class="test222">
                  <div class="headerTitle">
                    {{ articleInfo.title }}
                  </div>
                  <div>
                    <span class="category"
                      ><i class="fa fa-inbox"></i>&nbsp;&nbsp;{{
                        articleInfo.category_name
                      }}</span
                    >
                    <span
                      v-for="tagItem in articleInfo.tag_name"
                      :key="tagItem.id"
                      class="tag"
                      >#{{ tagItem.tag_name }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="articleContent">
              <div class="padding">
                <div class="background">
                  <div class="padding">
                    <div class="titleTop">
                      <div class="titile_like">
                        <h1 class="title">{{ articleInfo.title }}</h1>
                        <div v-if="articleIsLike" class="like_true">
                          <el-popover placement="right" :width="160">
                            <template #reference>
                              <i
                                class="fa fa-heart"
                                aria-hidden="true"
                                @click="like()"
                              ></i>
                            </template>
                            <template #default> 取消点赞。(＞﹏＜) </template>
                          </el-popover>
                        </div>
                        <div v-else class="like_false">
                          <el-popover placement="right" :width="160">
                            <template #reference>
                              <i
                                class="fa fa-heart-o"
                                aria-hidden="true"
                                @click="like()"
                              ></i>
                            </template>
                            <template #default>
                              喜欢这篇文章的话，请点赞一下。 ^_~
                            </template>
                          </el-popover>
                        </div>
                      </div>
                      <span class="createTime"
                        ><i class="fa fa-calendar"></i>&nbsp;&nbsp;{{
                          articleInfo.create_time
                        }}</span
                      >
                    </div>
                    <hr class="contentHr" />
                    <div
                      v-html="articleInfo.content"
                      class="content"
                      id="content"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 文章目录 -->
          <div class="catalogue_list">
            <!-- 标题 -->
            <div class="catalogue_title">目&nbsp;录</div>
            <!-- <el-divider /> -->
            <el-progress
              :text-inside="false"
              :stroke-width="3"
              :percentage="progressPercentage"
              :show-text="false"
              color="#0e9fe9"
            />
            <!-- 目录信息 -->
            <!-- <el-scrollbar class="scrollbar"> -->
            <div class="catalogue_info">
              <div
                v-for="item in catalogueInfo"
                :key="item"
                class="catalogue_item"
                :id="'catalogue_' + item.id"
                :style="{
                  'font-size': item.level == 2 ? '1.1rem' : '',
                  'margin-top': item.level == 2 ? '4px' : ''
                }"
              >
                <a :href="'#' + item.id">
                  <div :style="{ 'margin-left': 20 * (item.level - 2) + 'px' }">
                    {{ item.title }}
                  </div>
                </a>
              </div>
            </div>
            <!-- </el-scrollbar> -->
          </div>
        </div>
        <!-- 底部评论 -->
        <div class="comment">
          <!-- 评论输入框 -->
          <div class="comment_input">
            <div class="comment_title">
              <i class="fa fa-comments" aria-hidden="true"></i>
              评论
            </div>
            <el-input
              v-model="article_comment.comment_content"
              :rows="7"
              type="textarea"
              placeholder="说点什么吧...  (若内容或图片失效，请留言反馈。部分素材来自网络，若不小心影响到您的利益，请联系我们删除。)"
            />
            <div class="commentButton">
              <el-button
                v-if="$store.state.isLogin"
                type="primary"
                @click="insertArticleComment()"
                class="comment_button"
                :loading="articleCommentButtonLoading"
                >提交评论</el-button
              >
              <el-popover placement="left" v-else>
                <template #reference>
                  <el-button type="primary" class="comment_button"
                    >提交评论</el-button
                  >
                </template>
                <template #default> 需要登录才能发表评论哦。^_~ </template>
              </el-popover>
            </div>
          </div>
          <!-- 评论内容展示 -->
          <!-- <div
          class="comment_list"
          v-if="isShowComment"
        >
          <div
            v-for="item in articleCommentInfo"
            :key="item.id"
          >
            <div class="comment_info">
              <div class="avatar_photo_background">
                <img
                  :src="'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/'+item.user_avatar"
                  class="avatar_photo"
                />
              </div>
              <div class="comment_info_left">
                <div>
                  <span class="comment_user_nickname">{{item.user_nickname}}</span>
                  <span>:&nbsp;{{item.comment_content}}</span>
                </div>
                <span class="comment_time">
                  {{item.create_time_toDate}}&nbsp;&nbsp;
                  <span v-if="item.address_city != null">来自{{item.address_city}}</span>
                  <i
                    class="fa fa-commenting-o comment_icon"
                    @click="clickThreadedComment(item.id)"
                  ></i>
                </span>
              </div>
            </div>
            <div
              v-if="threadedCommentId == item.id"
              class="threaded_comment"
            >
              <el-input
                v-model="threaded_comment.comment_content"
                :placeholder="'回复@'+item.user_nickname"
                class="threaded_comment_input"
              />
              <el-button
                v-if="$store.state.isLogin"
                type="primary"
                @click="insertThreadedComment()"
                :loading="threadedCommentButtonLoading"
                class="threaded_comment_button"
              >回复</el-button>
              <el-popover
                placement="top"
                v-else
              >
                <template #reference>
                  <el-button
                    type="primary"
                    class="threaded_comment_button"
                  >回复</el-button>
                </template>
                <template #default>
                  需要登录才能回复哦。^_~
                </template>
              </el-popover>
            </div>
            <div
              v-for="item_child in item.child_article_comment"
              :key="item_child.id"
            >
              <div class="comment_info_child">
                <img
                  :src="'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/'+item_child.user_avatar"
                  class="avatar_photo"
                />
                <div class="comment_info_left">
                  <div>
                    <span class="comment_user_nickname">{{item_child.user_nickname}}</span>
                    <span v-if="item_child.father_user_id != null">:&nbsp;回复<span
                        class="comment_user_nickname">@{{item_child.second_father_user_nickname}}</span></span>
                    <span>:&nbsp;{{item_child.comment_content}}</span>
                  </div>
                  <span class="comment_time">
                    {{item_child.create_time_toDate}}&nbsp;&nbsp;
                    <span v-if="item_child.address_city != null">来自{{item_child.address_city}}
                      <i
                        class="fa fa-commenting-o comment_icon"
                        @click="clickThreadedComment(item_child.id,item.id)"
                      ></i>
                    </span>
                  </span>
                </div>
              </div> -->
          <!-- 回复评论输入框 -->
          <!-- <div
                v-if="threadedCommentId == item_child.id"
                class="threaded_comment"
              >
                <el-input
                  v-model="threaded_comment.comment_content"
                  :placeholder="'回复@'+item_child.user_nickname"
                  class="threaded_comment_input"
                />
                <el-button
                  v-if="$store.state.isLogin"
                  type="primary"
                  @click="insertThreadedComment()"
                  :loading="threadedCommentButtonLoading"
                  class="threaded_comment_button"
                >回复</el-button>
                <el-popover
                  placement="top"
                  v-else
                >
                  <template #reference>
                    <el-button
                      type="primary"
                      class="threaded_comment_button"
                    >回复</el-button>
                  </template>
                  <template #default>
                    需要登录才能回复哦。^_~
                  </template>
                </el-popover>
              </div> -->
          <!-- </div>
          </div>
        </div> -->
          <div class="comment_list" v-if="isShowComment">
            <div v-for="item in articleCommentInfo" :key="item.id">
              <MessageShow
                :messageDate="item"
                :style="{ margin_left: '0px', margin_top: '24px' }"
                :father_id="item.id"
                scene="first"
                :submitDataFunction="insertThreadedComment"
                @getData="selectArticleComment"
              >
                <!-- <template #avatar>
            <img :src="'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/'+item.user_avatar" />
          </template> -->
              </MessageShow>
              <div
                v-for="item_child in item.child_article_comment"
                :key="item_child.id"
              >
                <MessageShow
                  :messageDate="item_child"
                  :style="{ margin_left: '50px', margin_top: '16px' }"
                  :father_id="item.id"
                  scene="second"
                  :submitDataFunction="insertThreadedComment"
                  @getData="selectArticleComment"
                >
                </MessageShow>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </new-page>
</template>

<script>
import NewPage from '../../src/components/NewPage'
import { onMounted, reactive, ref, watch, onUnmounted } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
import {
  SELECTARTICLEBYID,
  LIKEARTICLE,
  SELECTARTICLELIKE,
  INSERTARTICLECOMMENT,
  SELECTARTICLECOMMENT
} from '../api/api.js'
import Prism from 'prismjs'
import { ElMessage } from 'element-plus'
import store from '../store/index'
import { setCookie } from '../tools/cookie.ts'
import MessageShow from '@/components/MessageShow.vue'
// import { nextTick } from 'vue'

export default {
  components: {
    MessageShow,
    NewPage
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    onMounted(() => {
      if (route.params.id === undefined) {
        router.push({ name: 'ArticleList' })
      } else {
        selectArticleById(route.params.id)
        is_like(route.params.id)
      }
      selectArticleComment()
      handleScroll()
    })
    onUnmounted(() => {
      // 离开页面，关闭滚动监听
      window.removeEventListener('scroll', test)
    })
    // 监听登录状态
    watch(
      () => store.state.isLogin,
      () => {
        is_like(route.params.id)
      }
    )
    // 文章信息展示
    const articleInfo = reactive({
      id: '',
      title: '',
      create_time: '',
      category_name: '',
      tag_name: '',
      content: '',
      cover_photo: ''
    })
    // 文章信息等待状态
    const articleLoading = ref(false)
    // 根据id查询数据
    const selectArticleById = async (id) => {
      articleLoading.value = true
      await SELECTARTICLEBYID({ id: id }).then((response) => {
        const {
          id,
          title,
          create_time,
          category_name,
          tag_name,
          content,
          cover_photo
        } = response.data
        articleInfo.id = article_comment.article_id = article_id.value = id
        articleInfo.title = title
        articleInfo.create_time = create_time
        articleInfo.category_name = category_name
        articleInfo.tag_name = tag_name
        articleInfo.content = content
        articleInfo.cover_photo = cover_photo
        articleLoading.value = false
      })
      await Prism.highlightAll()
      await getCatalogue()
    }
    // 处理图片展示路径
    const photoUrl = (url) => {
      if (url !== '') {
        return (
          'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/' +
          JSON.parse(url)[0]
        )
      }
    }
    // 文章点赞状态
    const articleIsLike = ref(false)
    // 点赞
    const like = () => {
      LIKEARTICLE({ article_id: articleInfo.id }).then((response) => {
        if (response.code === 0) {
          if (response.data) {
            // 点赞成功
            articleIsLike.value = true
            ElMessage({
              showClose: true,
              message: '感谢您的点赞，我会继续努力的！',
              type: 'success'
            })
          } else {
            // 取消点赞成功
            articleIsLike.value = false
            ElMessage({
              showClose: true,
              message: '感谢您曾经的点赞，如遇问题，欢迎留言！',
              type: 'info'
            })
          }
        } else if (response.code === -1) {
          ElMessage({
            showClose: true,
            message: response.msg,
            type: 'warning'
          })
          // 清空cookie
          setCookie('blogName', '', -1)
          setCookie('avatar', '', -1)
          setCookie('is_login', '', -1)
          // 更新vuex的状态
          store.commit('updateIsLogin', false)
          articleIsLike.value = false
        }
      })
    }
    // 获取点赞状态
    const is_like = (article_id) => {
      // 判断是否有登录
      if (store.state.isLogin) {
        // 拿id去查询是否有点赞
        SELECTARTICLELIKE({ article_id: article_id }).then((response) => {
          if (response.code === 0) {
            // 已登录
            articleIsLike.value = response.data
          } else if (response.code === -1) {
            // 未登录
            // 清空cookie
            setCookie('blogName', '', -1)
            setCookie('avatar', '', -1)
            setCookie('is_login', '', -1)
            // 更新vuex的状态
            store.commit('updateIsLogin', false)
            articleIsLike.value = false
          }
        })
      } else {
        // 如果后端没有登录,则清除缓存
        // 清空cookie
        setCookie('blogName', '', -1)
        setCookie('avatar', '', -1)
        setCookie('is_login', '', -1)
        articleIsLike.value = false
      }
    }
    // 提交评论内容
    const article_comment = reactive({
      comment_content: '',
      article_id: '',
      father_id: 0,
      comment_id: 0
    })
    // 文章id
    const article_id = ref()
    // 发表评论的等待按钮
    const articleCommentButtonLoading = ref(false)
    // 发表评论
    const insertArticleComment = () => {
      if (article_comment.comment_content === '') {
        ElMessage({
          showClose: true,
          message: '你忘记填写评论了！',
          type: 'warning'
        })
        return
      }
      articleCommentButtonLoading.value = true
      INSERTARTICLECOMMENT(article_comment).then((response) => {
        if (response.code === 0) {
          selectArticleComment()
          article_comment.comment_content = ''
          articleCommentButtonLoading.value = false
          ElMessage({
            showClose: true,
            message: '评论发表成功！',
            type: 'success'
          })
        } else if (response.code === -1) {
          ElMessage({
            showClose: true,
            message: response.msg,
            type: 'warning'
          })
          // 清空cookie
          setCookie('blogName', '', -1)
          setCookie('avatar', '', -1)
          setCookie('is_login', '', -1)
          // 更新vuex的状态
          store.commit('updateIsLogin', false)
          articleCommentButtonLoading.value = false
        }
      })
    }

    // 评论数据
    const articleCommentInfo = ref()
    // 评论内容展示状态
    const isShowComment = ref(false)
    // 获取评论
    const selectArticleComment = () => {
      SELECTARTICLECOMMENT({ article_id: route.params.id, father_id: 0 }).then(
        (response) => {
          if (response.code === 0) {
            articleCommentInfo.value = response.data
            if (articleCommentInfo.value.length === 0) {
              isShowComment.value = false
            } else {
              isShowComment.value = true
            }
          }
        }
      )
    }
    // 回复评论输入框展示id
    const threadedCommentId = ref()
    // 回复评论内容
    const threaded_comment = reactive({
      comment_content: '',
      article_id: '',
      father_id: 0,
      comment_id: 0
    })
    // 点击回复评论icon
    const clickThreadedComment = (activate_id, father_id) => {
      threaded_comment.comment_content = ''
      if (father_id === undefined) {
        threaded_comment.father_id = activate_id
      } else {
        threaded_comment.father_id = father_id
        threaded_comment.comment_id = activate_id
      }

      if (threadedCommentId.value === activate_id) {
        threadedCommentId.value = 0
        threaded_comment.comment_id = 0
      } else {
        threadedCommentId.value = activate_id
      }
    }
    // 回复评论按钮等待状态
    const threadedCommentButtonLoading = ref(false)
    // 提交回复评论
    const insertThreadedComment = (father_id, comment_id, message_contents) => {
      return new Promise((resolve, reject) => {
        INSERTARTICLECOMMENT({
          father_id: father_id,
          comment_id: comment_id,
          comment_content: message_contents,
          article_id: article_id.value
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
      // if (threaded_comment.comment_content === '') {
      //   ElMessage({
      //     showClose: true,
      //     message: '你忘记填写回复了！',
      //     type: 'warning'
      //   })
      //   return
      // }
      // threadedCommentButtonLoading.value = true
      // INSERTARTICLECOMMENT(threaded_comment).then((response) => {
      //   if (response.code === 0) {
      //     selectArticleComment()
      //     threaded_comment.comment_content = ''
      //     threaded_comment.comment_id = 0
      //     threadedCommentId.value = 0
      //     threadedCommentButtonLoading.value = false
      //     ElMessage({
      //       showClose: true,
      //       message: '回复发表成功！',
      //       type: 'success'
      //     })
      //   } else if (response.code === -1) {
      //     ElMessage({
      //       showClose: true,
      //       message: response.msg,
      //       type: 'warning'
      //     })
      //     // 清空cookie
      //     setCookie('blogName', '', -1)
      //     setCookie('avatar', '', -1)
      //     setCookie('is_login', '', -1)
      //     // 更新vuex的状态
      //     store.commit('updateIsLogin', false)
      //     threadedCommentButtonLoading.value = false
      //   }
      // })
    }

    // 回复评论按钮等待状态
    const catalogueInfo = ref()
    // 自动获取目录信息
    const getCatalogue = () => {
      const parent = document.getElementById('content')
      const doms = parent.querySelectorAll('h1,h2,h3,h4,h5,h6')
      const hEles = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']
      const catalogue = []
      let index = 0
      if (doms.length > 0) {
        doms.forEach((element) => {
          var nodetext = element.innerHTML.replace(/<\/?[^>]+>/g, '')
          nodetext = nodetext.replace(/&nbsp;/gi, '')
          const name = element.nodeName.toLowerCase()
          if (hEles.includes(name)) {
            index++
            const id = `catalogue_${index}`
            element.setAttribute('id', id)
            const level = name.replace('h', '')
            catalogue.push({
              id: id,
              key: name,
              title: nodetext,
              level: Number(level)
            })
          }
        })
        catalogueInfo.value = catalogue
      }
    }
    // 当前（历史）激活菜单id
    const historyActivate = reactive({ id: -1 })
    // 阅读进度
    const progressPercentage = ref()
    // 滚动的监听方法
    function handleScroll() {
      const main = document.getElementById('main-background')
      main.addEventListener('scroll', test)
    }
    const test = () => {
      const main = document.getElementById('main-background')
      // console.log(window.scrollY)
      // scrollTop.value = window.scrollY
      // 当前滚动的距离
      var current = main.scrollTop
      // 页面的总高度
      var offsetHeight = main.scrollHeight
      // 页面的可视高度
      var clientHeight = main.clientHeight

      // console.log(
      //   (current / (offsetHeight - clientHeight - 500)).toFixed(2) * 100
      // )
      progressPercentage.value =
        (current / (offsetHeight - clientHeight - 500)).toFixed(2) * 100
      if (current < 300) {
        // 当在头部时，激活第一个
        if (catalogueInfo.value) {
          activateCatalogueClass(catalogueInfo.value[0].id)
        }
      } else if (offsetHeight - clientHeight - current < 5) {
        // 当在最尾部，激活最后一个
        activateCatalogueClass(
          catalogueInfo.value[catalogueInfo.value.length - 1].id
        )
      } else {
        let activateCatalogue = ''
        if (catalogueInfo.value) {
          catalogueInfo.value.forEach((catalogue) => {
            if (
              current >=
              document.getElementById(catalogue.id).offsetTop - 25
            ) {
              activateCatalogue = catalogue
            }
          })
        }

        if (activateCatalogue) {
          // 激活菜单
          activateCatalogueClass(activateCatalogue.id)
        }
      }
    }
    // 首次进入判断滚动高度
    // const firstShow = () => {
    //   // 当前滚动的距离
    //   var current = document.documentElement.scrollTop
    //   if (current < 300) {
    //     // 当在头部时，激活第一个
    //     if (catalogueInfo.value) {
    //       activateCatalogueClass(catalogueInfo.value[0].id)
    //     }
    //   }
    // }
    // 激活目录
    const activateCatalogueClass = (id) => {
      if (id !== historyActivate.id) {
        // 取消激活菜单
        const historyCatalogue = document.getElementById(
          'catalogue_' + historyActivate.id
        )
        if (historyCatalogue) {
          historyCatalogue.classList.remove('catalogue_item_activate')
        }
        // 激活菜单
        const catalogue = document.getElementById('catalogue_' + id)
        if (catalogue) {
          catalogue.classList.add('catalogue_item_activate')
          historyActivate.id = id
        }
      }
    }

    return {
      articleInfo,
      photoUrl,
      articleLoading,
      like,
      articleIsLike,
      article_comment,
      articleCommentButtonLoading,
      insertArticleComment,
      articleCommentInfo,
      clickThreadedComment,
      threadedCommentId,
      threaded_comment,
      threadedCommentButtonLoading,
      insertThreadedComment,
      isShowComment,
      selectArticleComment,
      catalogueInfo,
      progressPercentage
    }
  }
}
</script>
<style>
.content p {
  line-height: 25px;
  /* text-indent: 2em; */
  padding-bottom: 8px;
}

.content p img {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
h2 {
  font-size: 1.5em;
  margin: 0.75em 0;
}
h3 {
  font-size: 1.17em;
  margin: 0.83em 0;
}
h5 {
  font-size: 0.83em;
  margin: 1.5em 0;
}
h6 {
  font-size: 0.75em;
  margin: 1.67em 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bolder;
}
</style>
<style scoped>
/* reset */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.articleMain {
  /* background-color: #f6f6f6; */
}

.container {
  /* 目的可以水平居中 */
  margin: 0px auto;
  /* 定义元素的最大宽度 */
  max-width: 1450px;
  padding: 16px 0;
  margin-top: 80px;
}

/* 目录 */
.catalogue_list {
  width: 300px;
  background-color: #ffffff90;
  height: 100%;
  margin-left: 8px;
  position: sticky;
  top: 8px;
  flex-shrink: 0;
  border-radius: 15px;
}

.catalogue_title {
  padding: 8px 0;
  width: 100%;
  text-align: center;
  font-weight: bolder;
}

/deep/ .el-progress-bar__outer {
  border-radius: 0;
}

/deep/ .el-progress-bar__inner {
  border-radius: 0;
}

.scrollbar {
  height: calc(100vh - 50px);
}

.catalogue_info {
  padding: 8px;
  color: #4e5969;
}

.catalogue_item {
  padding: 4px 8px;
}

.catalogue_item:hover {
  background-color: #0e9fe9;
  color: #fff;
  /* font-weight: bolder; */
}

.catalogue_item_activate {
  color: #fff;
  /* font-weight: bolder; */
  background-color: #0e9fe9;
}

a {
  text-decoration: none;
  color: inherit;
}

/* 鱼骨  */
.skeleton {
  background-color: #fff;
  margin-top: 8px;
  padding: 8px;
  width: 100%;
}

.coverPhoto {
  height: 200px;
  width: 100%;
  position: relative;
}

.coverPhoto::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  content: '';
}

.headerText {
  position: absolute;
  height: 200px;
  width: 100%;
}

.test222 {
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headerTitle {
  color: #f4f4f5;
  font-size: 36px;
  font-weight: bolder;
  letter-spacing: 4px;
  /* z-index: 2; */
}

.articleContent {
  background-color: #fff;
  overflow: hidden;
}

.content {
  padding: 16px;
}

.background {
  background-image: linear-gradient(
      90deg,
      rgba(60, 10, 30, 0.04) 3%,
      transparent 0%
    ),
    linear-gradient(0deg, rgba(60, 10, 30, 0.04) 3%, transparent 0%);
  background-size: 20px 20px;
  background-position: 10%;
}

.titleTop {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.titile_like {
  display: flex;
  align-items: center;
}

.title {
  font-weight: bold;
  letter-spacing: 4px;
}

.like_false {
  font-size: 20px;
  color: #409eff;
  animation: heart 0.8s infinite forwards linear;
  text-align: center;
  width: 40px;
  cursor: pointer;
}

@keyframes heart {
  0% {
    font-size: 20px;
  }

  /* 50% {
        font-size: 21px;

    } */

  100% {
    font-size: 19px;
  }
}

.like_true {
  color: #f56c6c;
  font-size: 22px;
  text-align: center;
  width: 40px;
  cursor: pointer;
}

.createTime {
  color: #606266;
  font-weight: bold;
  font-size: 1.2rem;
}

.padding {
  padding: 16px;
}

.contentHr {
  margin: 8px 0px;
  background-color: #cecfcf;
  border: none;
  height: 1px;
}

.category {
  color: #f4f4f5;
  font-weight: bold;
  font-size: 1.1rem;
  padding-right: 16px;
}

.tag {
  color: #f4f4f5;
  font-size: 0.9rem;
  padding-right: 8px;
}

.content p {
  line-height: 20px;
}

.comment {
  margin-top: 16px;
}

.comment_input {
  background-color: #ffffff90;
  padding: 16px;
  border-radius: 15px;
}

.comment_list {
  background-color: #ffffff90;
  padding: 16px;
  margin-top: 16px;
  border-radius: 15px;
}

.comment_title {
  color: #409eff;
  font-size: 20px;
  padding-bottom: 8px;
  padding-left: 8px;
}

.commentButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}

.comment_button {
  width: 100px;
}

.avatar_photo_background {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  background-color: #f4f4f4;
  margin-right: 8px;
  border-radius: 10px;
}

.avatar_photo {
  width: 40px;
}

.comment_user_nickname {
  color: #eb7350;
}

.comment_info {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;
  /* margin-top: 24px; */
}

.comment_info_left {
  width: 100%;
}

.comment_time {
  color: #808080;
  font-family: Arial, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei',
    'WenQuanYi Micro Hei', sans-serif;
  font-size: 12px;
  margin-top: 4px;
}

.comment_icon {
  margin-left: 16px;
  cursor: pointer;
  padding: 4px;
}

.comment_icon:hover {
  color: #eb7350;
  background-color: rgba(245, 178, 42, 0.2);
  border-radius: 50%;
}

.comment_info_child {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 40px; */

  margin: 16px 0px 16px 40px;
}

.threaded_comment {
  display: flex;
}

.threaded_comment_input {
  margin-left: 40px;
}

.threaded_comment_button {
  width: 100px;
  margin-left: 8px;
}
</style>
<!-- <style src="../css/article.css" scoped>
</style> -->
